import classNames from 'classnames';
import React from 'react';

type Props = {
  ariaLabelledby: string;
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size?: 20 | 24 | 32;
  fill?: string;
  className?: string;
  height?: number;
  width?: number;
} & React.HTMLAttributes<SVGElement>;

const Icon: React.FunctionComponent<Props> = ({
  ariaLabelledby,
  size = 24,
  fill = 'currentColor',
  className,
  height,
  width,
  component: Component,
  ...rest
}) => {
  return (
    <Component
      width={`${width || size}px`}
      height={`${height || size}px`}
      className={classNames('align-middle shrink-0', { className })}
      fill={fill}
      aria-labelledby={ariaLabelledby}
      {...rest}
    />
  );
};

export default Icon;
