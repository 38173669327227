import { getSanityImageResponsive, Image, Typography } from '@remarkable/ark-web';
import React from 'react';
import { extractGenericImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { ExperimentVariant } from 'src/services/experiments/optimize';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import sanityClient from 'src/utils/sanityClient';

interface Props {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
  experimentVariantId: ExperimentVariant;
}

const experimentIcons = [
  {
    src: 'Templates.svg',
    label: 'Templates',
    alt: 'Template icon',
  },
  {
    src: 'Stack.svg',
    label: 'Layers',
    alt: 'Layers icon',
  },
  {
    src: 'Eraser.svg',
    label: 'Erase',
    alt: 'Erase icon',
  },
  {
    src: 'Copy paste.svg',
    label: 'Copy and paste',
    alt: 'Copy paste icon',
  },
  {
    src: 'Undo.svg',
    label: 'Undo',
    alt: 'Undo icon',
  },
  {
    src: 'Resize.svg',
    label: 'Resize and move',
    alt: 'Resize and move icon',
  },
];

const FocusSection: React.FC<Props> = ({ sectionContent, experimentVariantId }) => {
  const header = getParagraphTextFromWebsiteContent(sectionContent, 'focus-header');
  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'focus-paragraph');
  const image = extractGenericImageBySelector(sectionContent, 'focus-image');

  return (
    <div className="flex flex-col w-full max-w-[624px]">
      <div className="basis-1/2">
        {experimentVariantId === ExperimentVariant.A ? (
          <Image className="w-full aspect-[1.48] max-h-[420px]" src="/images/LevelUp.jpeg" alt="Level up" />
        ) : (
          <Image
            className="w-full"
            {...getSanityImageResponsive({
              sanityClient: sanityClient,
              sanityImage: image,
              alt: image.alt ?? '',
              options: { width: 800 },
            })}
          />
        )}
      </div>
      <div className="text-left mt-20 lg:mt-28 basis-1/2 md:max-w-[624px]">
        <Typography variant="heading-sm" className="lg:text-42">
          {experimentVariantId === ExperimentVariant.A ? 'Level up your note-taking' : header}
        </Typography>
        <Typography variant="body-md-book" className="mt-8 md:text-16 lg:text-18 md:max-w-[512px]">
          {experimentVariantId === ExperimentVariant.A
            ? 'Transform your handwritten notes with powerful tools like undo, erase, resize, and move. Paper feel, digital flow.'
            : paragraph}
        </Typography>
      </div>
      {experimentVariantId === ExperimentVariant.A && (
        <div className="mt-8">
          <ul className="grid grid-cols-2 min-[1110px]:grid-cols-3 gap-12 lg:gap-x-16 w-max list-none pt-24">
            {experimentIcons.map((item) => {
              return (
                <li key={item.label} className="flex flex-row flex-wrap items-center w-max">
                  <Image src={'/svgs/' + item.src} alt={item.alt} className="h-24 w-24"></Image>
                  <p className="text-center  text-14 sm:text-16 ml-16">{item.label}</p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FocusSection;
