import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { StoreActionTypes } from 'src/redux/actions/storeActions2';
import * as RmStoreAPI from 'src/services/rm-store-api';
import { useCountryData } from './useCountryData';

export type TalonOneCustomerSession = {
  talonOne: { customerSession: { attributes: { referral_code_valid: string } } };
};

function getReferralCode(query: ParsedUrlQuery): string | null {
  if (typeof query.referralCode !== 'string') {
    return null;
  }

  return query.referralCode;
}

/**
 * Checks that router path is on referral route, extracts referral code, checks against backend,
 * applies referral code saving information in redux store.
 */
export const useApplyReferralCode: () => void = () => {
  const cart = useReduxCart();
  const router = useRouter();
  const dispatch = useDispatch();
  const { currency, countryHasBeenSet } = useCountryData();
  const referralCode = getReferralCode(router.query);

  useEffect(() => {
    const applyCode = async () => {
      if (!cart.id || !referralCode || !countryHasBeenSet) {
        return;
      }

      try {
        const res: TalonOneCustomerSession = await RmStoreAPI.applyReferralCode(referralCode, currency, cart.id);
        const isAccepted = res?.talonOne?.customerSession?.attributes?.referral_code_valid;

        if (isAccepted) {
          dispatch({
            type: StoreActionTypes.SET_REFERRAL_CODE,
            referralCode,
          });

          try {
            const product = await RmStoreAPI.getProductReferralDiscounts('RM110');

            if (product.skuHasDiscount) {
              const rm110ReferralDiscounts: { [currency: string]: number } = {};
              product.discounts.forEach((c) => {
                rm110ReferralDiscounts[c.currency] = c.amount;
              });

              dispatch({
                type: StoreActionTypes.SET_RM110_REFERRAL_DISCOUNTS,
                rm110ReferralDiscounts,
              });
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          console.error('Referral code not accepted');
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (router.isReady && countryHasBeenSet && currency && referralCode) {
      applyCode();
    }
  }, [router.isReady, countryHasBeenSet, currency, cart.id, dispatch, referralCode]);
};
