import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StoreActionTypes } from 'src/redux/actions/storeActions2';
import * as RmStoreAPI from 'src/services/rm-store-api';

import { useCountryData } from './useCountryData';

/**
 * Checks that router path is on discount route, extracts discount code, checks against backend,
 * applies discount code saving information in redux store.
 */
export const useApplyPromotionCode = (cart) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { currency, countryHasBeenSet } = useCountryData();

  useEffect(() => {
    try {
      if (!cart.loading && cart.data.discounts?.connectPromotions?.length > 0) {
        dispatch({
          type: StoreActionTypes.SET_PROMOTION_DISCOUNTS,
          connectPromotionDiscount: {
            [cart.data.discounts.connectPromotions[0].price.currency]:
              cart.data.discounts.connectPromotions[0].price.amount,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [cart.id, cart.loading, dispatch, cart.data?.discounts?.connectPromotions]);

  const applyCode = useCallback(
    async (promotionCode, currency, cartId): Promise<void> => {
      if (!cart.id || !promotionCode) {
        return;
      }
      try {
        const res: any = await RmStoreAPI.applyPromotionCode(promotionCode, currency, cartId);
        const isAccepted = res?.talonOne?.customerSession?.attributes?.connect_promotion_ref;

        if (isAccepted) {
          dispatch({
            type: StoreActionTypes.SET_PROMOTION_CODE,
            promotionCode: promotionCode,
          });
          cart.actions.refreshCart();
        } else {
          console.error('Discount code not accepted');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [cart.id, currency, dispatch]
  );

  useEffect(() => {
    if (router.isReady && cart.id && countryHasBeenSet) {
      const { promotionCode } = <{ promotionCode: string | undefined }>router.query;

      if (!!promotionCode && cart.id) {
        applyCode(promotionCode, currency, cart.id);
      }
    }
  }, [countryHasBeenSet, currency, cart.id, router.isReady, router.query, applyCode]);

  useEffect(() => {
    if (!cart.loading && cart.data?.discounts?.connectPromotions?.length > 0) {
      dispatch({
        type: StoreActionTypes.SET_PROMOTION_DISCOUNTS,
        connectPromotionDiscount: {
          [cart.data.discounts.connectPromotions[0].price.currency]:
            cart.data.discounts.connectPromotions[0].price.amount,
        },
      });
    }
  }, [cart?.data?.discounts.connectPromotions, currency]);
};
