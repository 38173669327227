import { Button } from '@remarkable/ark-web';
import cx from 'classnames';
import { PlayCircle, X } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import Player from 'react-player';
import { useSelector } from 'react-redux';
import Cross from 'src/assets/Icons/icon_cross_24.svg';
import Icon from 'src/components/Icon';
import { State } from 'src/redux/reducers';
import { eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { isBrowser } from 'src/utils';
interface Props {
  className?: string;
  url: string;
  duration?: string;
  ratio?: {
    height: number;
    width: number;
  };
  isPlaying?: (playing: boolean) => void;
  color?: 'white' | 'black';
}

const Video: React.FC<Props> = ({ duration, url, ratio, className, isPlaying, color = 'white' }) => {
  const playerThumb = useRef<HTMLButtonElement>(null);
  const playerWrap = useRef<HTMLDivElement>(null);

  const [videoActive, setVideoActive] = useState(false);
  const [beginCloseAnimation, setBeginCloseAnimation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const hasLoaded = useRef(false); // use ref instead of state to avoid multiple rerenders

  const iOS = /iPhone|iPod|iPad/.test(isBrowser() ? navigator.platform : '');
  const country = useSelector((state: State) => state.countryDetector.country);

  const exit = useCallback(() => {
    if (!playerWrap.current) return;

    playerWrap.current.addEventListener(
      'animationend',
      () => {
        setVideoActive(false);
        isPlaying && isPlaying(false);
        setBeginCloseAnimation(false);
        hasLoaded.current = false;
        document.body.classList.remove('h-screen', 'overflow-hidden');
      },
      { once: true }
    );

    setBeginCloseAnimation(true);
  }, [isPlaying]);

  const handleCloseKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        exit();
      }
    },
    [exit]
  );

  const handleCloseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const btnClick = (e.target as Element).closest('#close-player');
    const fadeClick = !(e.target as Element).closest('#videoplayer');
    // if ((btnClick || fadeClick) && hasLoaded) exit();
    if ((btnClick || fadeClick) && hasLoaded.current) exit();
  };

  const handleFocusReset = () => {
    /* zero-timeout is required per documentation:
       https://github.com/theKashey/react-focus-lock#unmounting-and-focus-management
    */

    setTimeout(() => {
      if (playerThumb.current) playerThumb.current.focus();
    }, 0);
  };

  useEffect(() => {
    if (!showModal) return;
    addEventListener('keydown', handleCloseKeyPress);
    return () => removeEventListener('keydown', handleCloseKeyPress);
  }, [showModal, handleCloseKeyPress]);

  const renderPlayer = () => {
    return (
      <div
        ref={playerWrap}
        className={cx(
          `
          fixed 
          flex justify-center items-center
          top-[-10px] right-0 left-0 bottom-0 
          w-full 
          z-[101] 
          pt-[10px] 
          transform translate-y-[10px] 
          bg-black sm:bg-[rgba(0,0,0,0.5)]
        `,
          {
            'opacity-0 transform translate-y-10 animate-fade-in-modal':
              videoActive && hasLoaded.current && !beginCloseAnimation,
            'opacity-100 transform translate-y-0 animate-fade-out-inline animate-fade-out-modal': beginCloseAnimation,
          }
        )}
        onClick={handleCloseClick}
      >
        <div
          className={cx(
            'flex justify-center items-center',
            'w-screen max-w-[calc(var(--aspect-ratio-X)_/_var(--aspect-ratio-Y)_*_100vh)]',
            'h-[calc(var(--aspect-ratio-Y)*100vw_/_var(--aspect-ratio-X))] max-h-screen'
          )}
          style={
            {
              '--aspect-ratio-X': ratio?.width || 16,
              '--aspect-ratio-Y': ratio?.height || 9,
            } as React.CSSProperties
          }
        >
          <FocusLock
            lockProps={{ id: 'videoplayer' }}
            autoFocus={false}
            disabled={!videoActive || !showModal}
            className={
              'relative h-full w-full bg-white box-content sm:h-3/4 sm:w-3/4 sm:border-[35px] sm:border-white sm:rounded-[9px]'
            }
            onDeactivation={handleFocusReset}
          >
            <Player
              data-cy="video-modal"
              width="100%"
              height="100%"
              onReady={() => {
                /*
                "onStart" Callback is never fired in iOS.
                known bug in React Player issue tracker.
                Makes loading a little jankier, thus using this
                as fallback
              */
                if (iOS) {
                  hasLoaded.current = true;
                }
              }}
              onStart={() => {
                if (!iOS) {
                  hasLoaded.current = true;
                }
              }}
              url={url}
              playing={videoActive}
              controls
              playsinline
              onProgress={({ playedSeconds }) => {
                const trackingMarks = [30, 60, 90, 120, 150, 180];
                const seconds = Math.floor(playedSeconds);

                if (trackingMarks.includes(seconds)) {
                  pushGAEventToDataLayer(
                    eventCategoryEnum.VIDEO_VIEW,
                    `Video progress ${country}`,
                    'Watched video',
                    seconds
                  );
                }
              }}
            />
            <Button
              variant="tertiary"
              data-theme="on-dark"
              size="medium"
              data-cy="close-player"
              id="close-player"
              onClick={() => {
                exit();
              }}
              className={cx([
                `absolute flex justify-center p-8 rounded-[50%]`,
                { 'top-[calc(-40vh+25%)] sm:top-[-50px]': true },
                { 'right-16 sm:right-[-50px]': true },
                {
                  'bg-grayscale-gray-800': true,
                  'hover:[&.ark-button--tertiary]:bg-grayscale-gray-900': true,
                  'hover:[&.ark-button--tertiary]:transition hover:[&.ark-button--tertiary]:duration-150': true,
                },
              ])}
              aria-label="Close"
            >
              <Icon component={Cross} ariaLabelledby="cross" size={24} fill="white" />
            </Button>
          </FocusLock>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const modalView = videoActive;
    setShowModal(modalView);

    if (modalView) document.body.classList.add('h-screen', 'overflow-hidden');
  }, [videoActive]);

  const handlePlayVideo = () => {
    setVideoActive(true);
    isPlaying && isPlaying(true);
  };

  const twColor = color === 'white' ? 'text-grayscale-white' : 'text-grayscale-gray-800';
  const iconColor = color === 'white' ? '#fff' : '#222';

  return (
    <>
      <div className={cx(className, 'flex flex-col items-center text-center w-[195px]')}>
        <Button
          data-cy="open-video-modal"
          loading={videoActive && !hasLoaded.current}
          data-theme={color === 'white' ? 'on-dark' : 'on-light'}
          variant="secondary"
          size="large"
          ref={playerThumb}
          className={`
            relative
            flex items-center justify-space-between
            h-48
            my-0 mx-auto
            py-0 px-[18px]
            ${twColor}
            `}
          onClick={() => (hasLoaded.current && videoActive ? exit() : handlePlayVideo())}
        >
          <div className={'h-20 w-20  flex items-center justify-center'}>
            <PlayCircle color={iconColor} size={26} className={`shrink-0 ${videoActive && 'hidden'}`} weight="fill" />
            <X
              color={iconColor}
              size={26}
              className={`block shrink-0 ${(!videoActive || !hasLoaded.current) && 'hidden'}`}
            />
          </div>
          <span className={'block font-demi text-16'}>See how it works</span>
        </Button>
        {duration && <span className={`${twColor} mt-[10px] uppercase text-14 w-full`}>{duration}</span>}
      </div>
      {showModal && renderPlayer()}
    </>
  );
};

export default Video;
