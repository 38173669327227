import { sanityImgObjectFromGenericImage } from 'src/components/Image/SanityImage/sanityImageHelpers';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { extractImageBySelector, getParagraphTextFromWebsiteContent } from 'src/helpers/sanityHelpers';
import { WebsiteSectionContentWithImages, WebsiteSectionContentWithTexts } from 'src/typings/websiteContent';
import Video from 'src/views/MainPage/components/Video';

const ScreenShare = ({
  sectionContent,
}: {
  sectionContent: WebsiteSectionContentWithTexts & WebsiteSectionContentWithImages;
}) => {
  if (sectionContent == null) {
    return null;
  }

  const paragraph = getParagraphTextFromWebsiteContent(sectionContent, 'paragraph');
  const image = extractImageBySelector(sectionContent, 'image');
  const imageMobile = extractImageBySelector(sectionContent, 'imageMobile');

  return (
    <section className="px-0 py-48 pt-48 text-left bg-brand-blue-50 md:py-[80px] lg:px-[72px] md:pt-64">
      <div className="flex flex-col items-center lg:flex-row max-w-[1360px] mx-auto">
        <div className="hidden lg:block relative lg:mt-0 lg:w-[650px]">
          <SanityResponsiveImage
            imgDefault={{
              sanityImgObject: { ...sanityImgObjectFromGenericImage(image) },
              fitMode: 'cover',
            }}
            imgSizeSet={[
              { bp: 320, sanityImgOptions: { width: 475 } },
              { bp: 475, sanityImgOptions: { width: 768 } },
              { bp: 768, sanityImgOptions: { width: 1024 } },
              { bp: 1024, sanityImgOptions: { width: 650 } },
            ]}
          />
        </div>

        <div className="w-full px-24 lg:w-1/2 lg:ml-[80px] lg:py-0 sm:px-[72px] lg:px-0">
          <div className="justify-start w-auto px-24 text-center md:px-0 lg:text-left">
            <p className="m-0 font-serif text-42 md:text-54">{sectionContent.header}</p>
          </div>

          <p className="hidden lg:block text-18 mt-24 max-w-[480px] leading-150">{paragraph}</p>

          <div className="hidden lg:inline-block">
            <Video url="https://youtu.be/_wtZXDmYHBA" className="mt-40" color="black" />
          </div>

          <div className="relative w-full mt-40 lg:hidden md:mt-48">
            <SanityResponsiveImage
              imgDefault={{
                sanityImgObject: { ...sanityImgObjectFromGenericImage(imageMobile) },
                fitMode: 'cover',
              }}
            />
          </div>

          <div className="lg:hidden">
            <p className="mt-16 leading-150 text-16 md:text-18 md:mt-32">{paragraph}</p>

            <Video url="https://youtu.be/_wtZXDmYHBA" className="mx-auto mt-32" color="black" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreenShare;
